<template>
  <div class="pa-2">
    <h4 class="settings-h4">Guest Accounts</h4>
    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <!--REQUIE TWO STEP AUTH-->
      <v-checkbox
        v-model="organization.casualUsersDefinitions.isTwoStep"
        @change="allowTwoStepChanged"
        :disabled="
          !organization.casualUsersDefinitions.isActive ||
          organization.status !== 0
        "
        class="pa-0 ma-0 mb-2 mw-200"
        label="Two-factor authentication"
        hide-details
      ></v-checkbox>

      <!-- <CasualLanguage :organization="organization" /> -->

      <TimezoneOffset
        :currentTimezoneOffset="organization.casualUsersDefinitions.timezoneOffsetInMinutes"
        :disabled="false"
        label="Timezone offset in folder names of guests"
        @offset-type-changed="offsetTypeChanged"
      />

      <!--DURATION - FILES LIFE TIME -->
      <CasualFilesLifeTime :organization="organization" />

      <!--POLICIES -->
      <span
        class="light-text"
        :class="[
          (!organization.casualUsersDefinitions.isActive ||
            organization.status !== 0) &&
            'low-op-disabeld',
        ]"
      >
        Policies:
      </span>
      <div class="d-flex mt-2">
        <!--POLICY FOR FILES GET IN -->
        <v-select
          v-model="organization.casualUsersDefinitions.policyIn"
          :items="computedPolicies"
          :disabled="
            !organization.casualUsersDefinitions.isActive ||
            organization.status !== 0
          "
          class="px-0 mx-3 py-0"
          label="Incoming Files"
          style="max-width: 200px"
          hide-details
        >
        </v-select>
        <!--POLICY FOR FILES GET OUT -->
        <v-select
          v-model="organization.casualUsersDefinitions.policyOut"
          :items="computedPolicies"
          :disabled="
            !organization.casualUsersDefinitions.isActive ||
            organization.status !== 0
          "
          class="px-0 mx-3 py-0"
          label="Outgoing Files"
          style="max-width: 200px"
          hide-details
        >
        </v-select>
      </div>

      <LicensesTable
        :casualsLicense="casualLicense"
        :allowCasualsChanged="allowCasualsChanged"
      />

      <OnlineHelp
        page="settings"
        section="casualSettings"
        title="Guest Accounts"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'
import CasualFilesLifeTime from './Childrens/CasualFilesLifeTime/CasualFilesLifeTime.vue'
import CasualLanguage from './Childrens/CasualLanguage/CasualLanguage.vue'
import LicensesTable from './Childrens/LicensesTable/LicensesTable.vue'
import TimezoneOffset from '@/components/BaseComponents/CasualCommonComponents/TimezoneOffset.vue'
import { api } from '@/config'
import { alertDialog } from '@/utils'

export default {
  name: 'CasualUserSettings',
  props: {
    organization: Object,
    loggedUser: Object,
    actionType: String,
    allowCasualsChanged: Function,
  },
  computed: {
    ...mapGetters(['policies']),
    computedPolicies() {
      if (this.actionType === 'edit') return this.policies
      return []
    },
    casualLicense() {
      return this.organization.allowedLicenses.find(el => el.type === 'Casual')
    },
  },
  methods: {
    ...mapActions(['GET_POLICIES']),
    ...mapMutations(['SET_PROCESSING']),
    async allowTwoStepChanged(val) {
      //check if all casuals have phone number for 2fa
      if (!val) return

      try {
        this.SET_PROCESSING(true)
        const isAllCasualsHavePhoneRes = await api.get(
          'casuals/is-all-have-phone-number'
        )

        if (isAllCasualsHavePhoneRes.status !== 200) throw Error

        if (!isAllCasualsHavePhoneRes.data.isAllHavePhoneNumber) {
          alertDialog(
            this,
            'All guests must have a phone number to enable 2FA',
            'Confirm'
          )
          this.organization.casualUsersDefinitions.isTwoStep = false
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    offsetTypeChanged(val) {
      this.organization.casualUsersDefinitions.timezoneOffsetInMinutes = val
    },
  },
  async created() {
    await this.GET_POLICIES(this.organization.organizationId)
  },
  components: {
    OnlineHelp,
    CasualFilesLifeTime,
    CasualLanguage,
    LicensesTable,
    TimezoneOffset,
  },
}
</script>
