<template>
  <div>
    <div class="mt-4">
      <div class="pa-2 wrapper-1 ma-2 groups-wrapper">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <span class="fs-1r"
              >{{
                parent === 'user' ? $t('Groups') : 'Groups'
              }}:</span
            >
          </div>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="addGroupClicked" v-on="on" class="ma-2 pointer"
                  >mdi-plus</v-icon
                >
              </template>
              <span>
                {{ parent === 'user' ? $t('Add Group') : 'Add Group' }}
              </span>
            </v-tooltip>
          </div>
        </div>
        <div style="overflow: auto; display: flex">
          <div v-for="item in loggedUserGroupsData" class="mt-2 d-flex">
            <Button
              :clickAction="() => (casualGroupAddEdit = item)"
              :text="item.name"
              class="mx-1"
            />
            <div v-if="parent === 'admin' && user.license === 'SFTP'">
              <Button
              text="SFTP Access Details for Uploading"
              btnType="grey"
              class="mx-2"
              :clickAction="() => sftpDetailsUploadingClicked(item)"
              />
              <Button
                text="SFTP Access Details for Donloading"
                btnType="grey"
                class="mx-2"
                :clickAction="() => sftpDetailsDownloadingClicked(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEditCasualGroupPopUp
      v-if="casualGroupAddEdit"
      :closeDialog="() => (casualGroupAddEdit = false)"
      :casualGroupAddEdit="casualGroupAddEdit"
      :saveCasualGroupPopUp="saveCasualGroupPopUp"
      :deleteGroupClicked="deleteGroupClicked"
      :casualUsers="loggedUserCasualsData"
      :casualGroups="loggedUserGroupsData"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import AddEditCasualGroupPopUp from './AddEditCasualGroupPopUp.vue'
import SftpDetailsDialog from '../../SingleOrganization/Routes/SingleRoute/Children/SourceOrTargetItem/Childrens/SftpDetailsDialog/SftpDetailsDialog.vue'
import { api } from '@/config'
import { confirmDialog } from '@/utils'
import { mapMutations } from 'vuex'

export default {
  name: 'CasualGroups',
  data() {
    return {
      casualGroupAddEdit: null,
    }
  },
  props: { user: Object, parent: String, setIsTarget: Function },

  components: { Button, AddEditCasualGroupPopUp, SftpDetailsDialog },

  computed: {
    loggedUserCasualsData() {
      return this.user.casualUsers
    },
    loggedUserGroupsData() {
      return this.user.casualGroups
    },
  },
  emits: ['setGroup'],
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    addGroupClicked() {
      this.casualGroupAddEdit = { name: '', casualUsers: [] }
    },
    sftpDetailsUploadingClicked(group){
      this.$emit('setGroup', group)
      this.setIsTarget(false)
    },
    sftpDetailsDownloadingClicked(group){
      this.$emit('setGroup', group)
      this.setIsTarget(true)
    },
    async saveCasualGroupPopUp() {
      try {
        this.SET_PROCESSING(true)

        let isChanged = false

        //for edit
        const groupsArray = this.user.casualGroups.map(group => {
          if (this.casualGroupAddEdit.name === group.name) {
            isChanged = true
            return {
              name: this.casualGroupAddEdit.name,
              casualUsers: this.casualGroupAddEdit.casualUsers,
            }
          } else return group
        })

        //for add
        if (!isChanged) {
          groupsArray.push({
            name: this.casualGroupAddEdit.name,
            casualUsers: this.casualGroupAddEdit.casualUsers,
          })
        }

        const res = await api.put(
          `casuals/${this.user.userId}/update-casuals`,
          {
            groups: groupsArray,
            casuals: this.user.casualUsers,
          }
        )

        if (res.status !== 200) throw new Error('Error updating casuals')
        this.user.casualUsers = res.data.casuals
        this.user.casualGroups = res.data.groups
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
        this.casualGroupAddEdit = null
      }
    },
    async deleteGroupClicked() {
      try {
        this.SET_PROCESSING(true)
        confirmDialog(
          this,
          this.$t('Are you sure you want to delete this group?'),
          this.$t('Delete Group'),
          this.$t('Cancel'),
          async () => {
            try {
              console.log('hi')
              console.log(this.casualGroupAddEdit)
              console.log(this.user.casualGroups)
              const groupsArray = this.user.casualGroups.filter(
                group => group.name !== this.casualGroupAddEdit.name
              )

              console.log('hi 2')
              const res = await api.put(
                `casuals/${this.user.userId}/update-casuals`,
                {
                  casuals: this.user.casualUsers,
                  groups: groupsArray,
                }
              )
              console.log('hi 3')

              if (res.status !== 200) throw new Error('Error updating casuals')
              this.casualUserAddEdit = null
              this.user.casualUsers = res.data.casuals
              this.user.casualGroups = res.data.groups
              this.casualGroupAddEdit = null
            } catch (error) {
              console.log(error)
            }
          }
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>
<style>
.groups-wrapper {
  width: fit-content;
}
</style>
