import { api, selectedHost } from '@/config'
import { i18n } from '@/main'
import store from '@/store'
import {
  alertDialog,
  confirmDialog,
  generateLocalDate,
  generateNewName,
  isFolderNameValid,
  parseIcon,
} from '@/utils'
import * as tus from 'tus-js-client'

export default {
  state: {
    session: {
      subject: '',
      message: '',
      filesData: [],
      totalSize: 0,
      passwords: '',
      sizeOfUploadedData: 0,
      operationId: '',
      recipientEmail: '',
      recipientName: '',
    },
    filteringErrMessage: '',
    freezedTotalSize: 0,
    startTime: '',
    errors: {
      isSubjectError: false,
    },
    isDrag: false,

    //=====THROGH SESSION=====
    upload: null,
    timer: 0,
    curFileUploaded: {
      file: {
        name: '',
        size: 0,
        numbSize: 0,
        progress: 0,
        progressPercent: 0,
        icon: '',
        lastModifiedDate: '',
        path: '',
        isMoreDetails: false,
      },
      data: null,
    },
    sessionFiles: {
      total: 0,
      progress: 0,
    },
    sessionSize: {
      total: 0,
      progress: 0,
    },
    filteringProgress: {
      dataSent: 0,
      analyzedPercentage: 0,
      synthesizedPercentage: 0,
    },
    sseEvents: null,
    sse: null,
    interval: null,
    isInMiddleOfFiltering: false,
    token: '',
  },
  mutations: {
    resetState(state) {
      console.log('hi 0')
      state.session = {
        subject: '',
        message: '',
        filesData: [],
        totalSize: 0,
        passwords: '',
        sizeOfUploadedData: 0,
        operationId: '',
        recipientEmail: '',
        recipientName: '',
      }
      state.filteringErrMessage = ''
      state.freezedTotalSize = 0
      state.startTime = ''
      state.errors = {
        isSubjectError: false,
      }
      state.isDrag = false

      //=====THROGH SESSION=====
      state.upload = null
      state.timer = 0
      state.curFileUploaded = {
        file: {
          name: '',
          size: 0,
          numbSize: 0,
          progress: 0,
          progressPercent: 0,
          icon: '',
          lastModifiedDate: '',
          path: '',
          isMoreDetails: false,
        },
        data: null,
      }
      state.sessionFiles = {
        total: 0,
        progress: 0,
      }
      state.sessionSize = {
        total: 0,
        progress: 0,
      }
      state.filteringProgress = {
        dataSent: 0,
        analyzedPercentage: 0,
        synthesizedPercentage: 0,
      }
      state.sseEvents = null
      state.sse = null
      state.interval = null
      state.isInMiddleOfFiltering = false
      state.token = ''
      console.log('hi 1')
    },
    setSessionData(state, data) {
      state.session = data
    },
    setSessionField(state, { field, value }) {
      state.session[field] = value
    },
    removeItemFromSessionFilesData(state, index) {
      state.session.filesData.splice(index, 1)
    },
    setSessionFilesField(state, { field, value }) {
      state.sessionFiles[field] = value
    },
    addItemToFilesData(state, value) {
      state.session.filesData.push(value)
    },
    setSessionSizeField(state, { field, value }) {
      state.sessionSize[field] = value
    },
    setFreezedTotalSize(state, value) {
      state.freezedTotalSize = value
    },
    addSseEvent(state, event) {
      state.sseEvents.push(event)
    },
    setSse(state, sse) {
      state.sse = sse
    },
    clearSseEvents(state) {
      state.sseEvents = []
    },
    setfilteringProgress(state, { field, value }) {
      state.filteringProgress[field] = value
    },
    setStartTime(state, value) {
      state.startTime = value
    },
    setCurFileUploaded(state, value) {
      state.curFileUploaded = value
    },
    setUploadProgressPercent(state, { bytesUploaded, bytesTotal }) {
      if (state.curFileUploaded && state.curFileUploaded.file) {
        state.curFileUploaded = {
          ...state.curFileUploaded,
          file: {
            ...state.curFileUploaded.file,
            progressPercent: Math.round((bytesUploaded / bytesTotal) * 100),
          },
        }
      }
    },
    setUploadProgress(state, bytesUploaded) {
      if (state.curFileUploaded && state.curFileUploaded.file) {
        state.curFileUploaded.file.progress = bytesUploaded
      }
    },
    setUpload(state, value) {
      state.upload = value
    },
    setSetInterval(state) {
      state.interval = setInterval(() => (state.timer += 1000), 1000)
    },
    setClearInterval(state) {
      clearInterval(state.interval)
    },
    setTimer(state, value) {
      state.timer = value
    },
    setFilesSendTo(state, value) {
      state.filesSendTo = value
    },
    setHostSendTo(state, value) {
      state.hostSendTo = value
    },
    deleteFileFromSession(state, fileName) {
      state.session.filesData = state.session.filesData.filter(
        el => el.file.name != fileName
      )
    },
    setToken(state, token) {
      state.token = token
    },
    setIsInMiddleOfFiltering(state, value) {
      state.isInMiddleOfFiltering = value
    },
  },
  actions: {
    async reciveCasualUploadData({ commit }, payload) {
      api.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
      const response = await api.get(
        `casuals/casual-sending-details/${payload.casualEmail}/${payload.recipientEmail}`
      )
      // commit('setSessionData', response.data)

      commit('setFilesSendTo', response.data.registeredRecipient.name)
      commit('setHostSendTo', response.data.orgName)
    },

    async startFiltering({ commit, state, dispatch }, vue) {
      //this function runs when user start filtering
      // store.commit('SET_NOTIFICATION', null) ????
      try {
        commit('setIsInMiddleOfFiltering', true)
        const totalSize = state.session.totalSize
        commit('setTimer', 0)
        //this is to show the progress
        commit('setSessionFilesField', {
          field: 'total',
          value: state.session.filesData.length,
        })
        commit('setSessionSizeField', { field: 'total', totalSize })

        //to end of the session
        commit('setFreezedTotalSize', totalSize)
        commit('setStartTime', generateLocalDate(new Date()))

        const body = {
          passwords: state.session.passwords,
          subject: state.session.subject,
          message: state.session.message,
          totalFiles: state.session.filesData.length,
          emails: [state.session.recipientEmail],
        }
        //this will get the operation id
        const res = await api.post('filter/casual', body)
        if (res.status > 204) throw Error //res need to be 201
        const operationId = res.data
        state.session.operationId = operationId

        //open new event source with the server
        const evt = new EventSource(
          `${selectedHost}/api/Filter/progress?OperationId=${operationId}`
        )
        evt.onmessage = function (event) {
          const values = event.data.split(',')
          //this is for the progress of the files
          commit('setfilteringProgress', {
            field: 'dataSent',
            value: ((values[0] * 100) / totalSize).toFixed(2),
          })
          commit('setfilteringProgress', {
            field: 'analyzedPercentage',
            value: ((values[1] * 100) / totalSize).toFixed(2),
          })
          commit('setfilteringProgress', {
            field: 'synthesizedPercentage',
            value: ((values[2] * 100) / totalSize).toFixed(2),
          })
        }

        //======= FINISHED - GET REPORT =======
        //when the filering was finished
        evt.addEventListener(
          'Completed',
          async function (e) {
            //e.data represent how much files sent
            if (+e.data !== state.sessionFiles.total) return
            //close the event source
            evt.close()
            commit('setCurFileUploaded', null)
            commit('setUpload', null)

            const summary = await api.get(
              `/Filter/summary?OperationId=${operationId}`
            )
            if (summary.status !== 200) throw Error
            //get from the server the html report if the logged user can see it

            const statusResults = ['partial', 'rejected']
            let isShowOnlySome
            for (const key of statusResults) {
              const result = summary.headers[`${key}-files`]
              if (result > 0) {
                isShowOnlySome = true
                break
              }
            }
            if (isShowOnlySome) {
              alertDialog(
                vue,
                vue.$t(
                  'According to the security policy, only some of the files reached their destination'
                )
              )
            }

            //save the session results
            // state.statusResults.session = JSON.parse(JSON.stringify(vue.session)) ????

            //clear the operation id
            const clearOpIdRes = await api.delete(
              `filter?operationId=${operationId}`
            )
            if (clearOpIdRes.status !== 200) throw Error

            commit('setClearInterval')
            commit('setIsInMiddleOfFiltering', false)
            commit('setfilteringProgress', { field: 'dataSent', value: 100 })
            commit('setfilteringProgress', {
              field: 'analyzedPercentage',
              value: 100,
            })
            commit('setfilteringProgress', {
              field: 'synthesizedPercentage',
              value: 100,
            })
            commit('setSessionField', { field: 'filesData', value: [] })

            //if the connection is from mobile
            // if (connectionType === 'mobile') vue.step = 4

            state.session.subject = ''
            state.session.message = ''
            commit('SET_NOTIFICATION', {
              type: 'success',
              text: vue.$t('Upload process has been completed successfully'),
            })
          },
          false
        )

        //when there is an error
        evt.addEventListener(
          'Error',
          async function (e) {
            evt.close()
            commit('setSessionField', { field: 'filesData', value: [] })
            //clear the operation id
            await api.delete(`filter?operationId=${operationId}`)

            alertDialog(vue, decodeURIComponent(e.data))

            //resart the session
            commit('resetState')
            // vue.restartSessionClicked() ????
          },
          false
        )

        commit('setSse', evt)
        //interval to count the time
        // vue.interval = setInterval(() => (vue.timer += 1000), 1000) ????
        commit('setSetInterval')

        const duplicateFilesData = state.session.filesData.slice()

        for (const file of duplicateFilesData) {
          commit('setCurFileUploaded', file)
          await dispatch('sendFileData', { file, operationId }) //????
          let progress = state.sessionFiles.progress
          progress++
          commit('setSessionFilesField', { field: 'progress', value: progress })
          commit('setSessionSizeField', {
            field: 'progress',
            value: file.file.numbSize,
          })
        }

        const setOpIdRes = await api.put(`filter?operationId=${operationId}`)
        if (setOpIdRes.status !== 200) throw Error
      } catch (error) {
        console.log(error)
        commit('setClearInterval')
        commit('setIsInMiddleOfFiltering', false)
        commit('setSessionField', { field: 'totalSize', value: 0 })
        commit('setSessionField', { field: 'filesData', value: [] })
      } finally {
        commit('setSessionField', { field: 'sizeOfUploadedData', value: 0 })
      }
    },

    async sendFileData({ commit, state, dispatch }, { file, operationId }) {
      // const { loggedUser } = store.getters

      return new Promise((resolve, reject) => {
        try {
          if (!file) return

          const headers = {
            OperationId: operationId,
            authorization: `Bearer ${state.token}`,
          }

          //the object to the tus upload file connection
          const uploadObj = {
            endpoint: `${selectedHost}/api/Filter/file`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            headers,
            metadata: {
              filename: file.file.name,
              filetype: file.data.type,
              filepath: file.file.path,
            },

            //when there is an tus error
            onError: async function (error) {
              console.log(error)
              state.sse.close()
              // .toString()
              // .split('response text: ')
              // .pop()
              // .split(',')[0]
              commit('clearSse')
              commit('setSse', null)
              store.commit('SET_NOTIFICATION', { type: 'error', text: error })
              commit('setSessionField', { field: 'filesData', value: [] })
              commit('setSessionField', { field: 'totalSize', value: 0 })

              //clear the operation id
              await api.delete(`filter?operationId=${operationId}`)
            },

            //when the file was successfully uploaded
            onSuccess: function () {
              dispatch('deleteItem', file)
              resolve(true)
            },
          }

          //on the tus progress
          uploadObj.onProgress = function (bytesUploaded, bytesTotal) {
            commit('setUploadProgressPercent', { bytesUploaded, bytesTotal })
            commit('setUploadProgress', bytesUploaded)
            const evt = new CustomEvent('progressEvent')
            window.dispatchEvent(evt)
          }

          //this is create the connection for the file
          commit('setUpload', new tus.Upload(file.data, uploadObj))

          state.upload.start()
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    },

    deleteItem({ commit, state }, item) {
      //this function will delete item from the files array
      commit('setSessionField', {
        field: 'totalSize',
        value: state.session.totalSize - item.file.numbSize,
      })
      commit('setSessionField', {
        field: 'sizeOfUploadedData',
        value: state.session.sizeOfUploadedData + item.file.numbSize,
      })
      const index = state.session.filesData.indexOf(item)
      commit('removeItemFromSessionFilesData', index)
    },

    fileAddedFromInput({ commit, state, dispatch }, e) {
      //the input for the files "clicked"
      const files = Array.from(e.target.files)
      if (!files) return

      files.forEach(file => {
        const path = file.webkitRelativePath.slice(
          0,
          file.webkitRelativePath.lastIndexOf('/') + 1
        )
        dispatch('addFileItem', { file, path })
      })
    },

    addFileItem({ commit, state }, { file, path }) {
      //this function adds files to the session object
      const fileObj = {
        file: {
          name: file.name.replaceAll(/[\/\\:*?"<>]/g, ''),
          size: Math.ceil(file.size / 1024),
          numbSize: file.size,
          progress: 0,
          progressPercent: 0,
          icon: parseIcon(file.name),
          lastModifiedDate: generateLocalDate(file.lastModified),
          path,
          isMoreDetails: false,
        },
        data: file,
      }
      // this check if the file have uniqe name and if so it adds (x)
      if (
        state.session.filesData.some(
          el =>
            el.file.name === fileObj.file.name &&
            el.file.path === fileObj.file.path
        )
      ) {
        fileObj.file.name = generateNewName(
          fileObj.file.name,
          state.session.filesData
        )
      }
      if (!state.session.totalSize) {
        commit('setSessionField', { field: 'totalSize', value: 0 })
      }
      commit('setSessionField', {
        field: 'totalSize',
        value: (state.session.totalSize += fileObj.data.size),
      })
      commit('addItemToFilesData', fileObj)
    },

    async checkUploadData({ state, rootGetters, dispatch }) {
      // if (!rootGetters.interactiveInterfaceFields.subjectFieldText) {
      //   await dispatch('GET_INTERACTIVE_INTERFACE_FIELDS_DATA', null, {
      //     root: true,
      //   })
      //   console.log(rootGetters.interactiveInterfaceFields.subjectFieldText)
      // }
      if (!state.session.subject) {
        //TODO SUBJECT OR REQUEST ID
        if (
          rootGetters.interactiveInterfaceFields.subjectFieldText === 'Subject'
        )
          return i18n.t('Subject is required')
        else return i18n.t('Request number is required')
      }

      if (!isFolderNameValid(state.session.subject)) {
        if (interactiveInterfaceFields.subjectFieldText === 'Subject')
          return i18n.t('Subject is invalid')
        else return i18n.t('Request number is invalid')
      }

      if (!state.session.filesData.length) {
        return i18n.t('Files for filtering are missing')
      }
    },

    async cancelUploading({ state, commit }, vue) {
      //pause the upload
      state.upload.abort()

      //show dialog that ask the user if he is sure
      const text = i18n.t('Are you sure you want to stop the uploading') + '?'

      const thenFunc = async () => {
        clearInterval(state.interval)
        commit('setIsInMiddleOfFiltering', false)
        //clear the operation id
        const res = await api.delete(
          `filter?operationId=${state.session.operationId}`
        )
        if (res.status !== 200) throw Error('api error')
        //this will restart the session
        state.session.filesData = []
        state.session.totalSize = 0
        state.session.sizeOfUploadedData = 0
        state.session.operationId = ''
      }

      const catchFunc = e => {
        // if the error is the response fro mthe server
        if (e === 'api error') return
        state.upload.findPreviousUploads().then(function (previousUploads) {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            state.upload.resumeFromPreviousUpload(previousUploads[0])
          }

          // Start the upload again
          state.upload.start()
        })
      }

      confirmDialog(
        vue,
        text,
        vue.$t('Stop'),
        vue.$t('Cancel'),
        thenFunc,
        catchFunc
      )
    },
  },
  getters: {
    getSessionData: state => state.session,
    getFilesSendTo: state => state.filesSendTo,
    getHostSendTo: state => state.hostSendTo,
    getFilteringProgressData: state => state.filteringProgress,
    getSessionFilseData: state => state.sessionFiles,
    getSessionSizeData: state => state.sessionSize,
    getTimer: state => state.timer,
    getIsInMiddleOfFilteingData: state => state.isInMiddleOfFiltering,
    getCurFileProgressPercent: state =>
      state.curFileUploaded?.file.progressPercent,
    getCurFileName: state => state.curFileUploaded?.file.name,
  },
}
