<template>
  <div>
    <div class="selected-files-list">
      <div class="selected-files-list-header">
        <h3 class="fs-1-12r">{{ $t('Selected Files') }}</h3>
        <v-icon
          v-if="getIsInMiddleOfFilteingData"
          @click="cancelFileringClicked"
          color="red"
          >mdi-close-circle-outline</v-icon
        >
      </div>

      <v-data-table
        class="wrapper-1 dir-ltr"
        :headers="selectedFilesHeaders"
        :items="getSessionData.filesData"
        :options="{ sortBy: ['name'] }"
        must-sort
        :hide-default-footer="getSessionData.filesData.length < 10"
        dense
      >
        <!--NAME-->
        <template v-slot:item.name="{ item }">
          <span class="file-name"
            >{{ item.file.name }}
            <span class="size"
              >&nbsp;({{ item.data.size | bytesToSize }})</span
            ></span
          >
        </template>

        <!--DELETE-->
        <template v-slot:item.delete="{ item }">
          <v-icon
            v-if="!getIsInMiddleOfFilteingData"
            @click="() => deleteFileClicked(item.file.name)"
            color="red"
            >mdi-delete-circle-outline</v-icon
          >
          <div v-else-if="item.file.name === getCurFileName">
            <div class="d-flex align-center justify-center progress-cell">
              <v-progress-circular
                v-if="progress"
                :value="progress"
                :size="36"
                color="primary"
                class="my-auto mx-2"
                >{{ progress }}</v-progress-circular
              >
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { alertDialog, bytesToSize, confirmDialog } from '@/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SelectedFilesList',
  filters: { bytesToSize },
  computed: {
    ...mapGetters([
      'getSessionData',
      'getIsInMiddleOfFilteingData',
      'getCurFileProgressPercent',
      'getCurFileName',
    ]),
    progress() {
      return this.getCurFileProgressPercent
    },
    selectedFilesHeaders() {
      return [
        {
          text: this.$t('File Name (Size)'),
          value: 'name',
          width: '80%',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        },

        {
          text: '',
          value: 'delete',
          align: 'center',
          width: '20%',
          sortable: false,
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-first' : 'table-header-last'
          }`,
        },
      ]
    },
  },
  methods: {
    ...mapMutations(['deleteFileFromSession']),
    ...mapActions(['cancelUploading']),
    deleteFileClicked(itemName) {
      this.deleteFileFromSession(itemName)
    },
    cancelFileringClicked() {
      this.cancelUploading(this)
    },
  },
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0 !important;
}
.selected-files-list {
  width: 100%;
}

.selected-files-list-header {
  font-size: 1.12rem;
  margin: 0 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-file {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  margin: 8px 0;
}

.file-name {
  font-size: 1rem;

  .size {
    font-weight: normal;
    font-size: 0.75rem;
  }
}
</style>
