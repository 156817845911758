<template>
  <div v-if="isDataLoaded" class="single-user-wrapper">
    <!--HEADER AND BUTTONS-->
    <HeaderAndButtons
      :freezedProfile="freezedProfile"
      :actionType="actionType"
      :checkBeforeSaveRequireAlert="checkBeforeSaveRequireAlert"
      :loading="loading"
      :isFromUsers="isFromUsers"
      :message="message"
      :isSaveButtonDisabled="isSaveButtonDisabled"
    />
    <div class="single-user pos-relative">
      <!--DATA ABOUT EDIT AND CREATE-->
      <EditDetails :parentObj="profile" editOf="user" class="top-5" />

      <!--PERSONAL DATA-->
      <Personal
        class="my-6"
        @changeAccountType="v => (accountType = v)"
        :actionType="actionType"
        :isFromUsers="isFromUsers"
        :profile="profile"
        :freezedProfile="freezedProfile"
        :accountType="accountType"
        :isFieldDisabled="isFieldDisabled"
      />

      <!--BASIC INFORMATION-->
      <BasicInformation
        @set-is-email-uniqe="val => (isEmailUniqe = val)"
        @setLoading="v => (loading = v)"
        :profile="profile"
        :actionType="actionType"
        :fullName="fullName"
        :isEmailUniqe="isEmailUniqe"
        :isFieldDisabled="isFieldDisabled"
      />

      <!--USER COMMUNCATIONS-->
      <UserCommunications
        :profile="profile"
        :accountType="accountType"
        :actionType="actionType"
        :isFieldDisabled="isFieldDisabled"
      />

      <!--USER ROUTES-->
      <UserRoutes
        v-if="isShowUserRoutesComponent"
        :profile="profile"
        :isFieldDisabled="isFieldDisabled"
      />

      <!--USER CASUAL USERS-->
      <CasualUsers
        v-if="isShowCasualUsers"
        :profile="profile"
        :isFieldDisabled="isFieldDisabled"
        :casualLicense="casualLicense"
        :freezedProfile="freezedProfile"
      />

      <!-- THE USER STATUS-->
      <UserStatus
        v-if="actionType === 'edit'"
        :profile="profile"
        :freezedProfile="freezedProfile"
      />
    </div>
  </div>

  <div v-else>
    <v-skeleton-loader
      boilerplate
      type="table"
      tile
      class="mx-auto"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  validateEmail,
  getUTC0MSDateFromLocalDate,
  alertDialog,
  confirmDialog,
} from '@/utils'
import { getEmptyUser } from '@/actions/users'

//Base components
import EditDetails from '@/components/BaseComponents/EditDetails/EditDetails.vue'

//Childrens
//need to make new view to the user routes
//need to make new org routes for single user
import Personal from './Children/Personal/Personal.vue'
import BasicInformation from './Children/BasicInformation/BasicInformation.vue'
import UserCommunications from './Children/UserCommunications/UserCommunications.vue'
import HeaderAndButtons from './Children/HeaderAndButtons/HeaderAndButtons.vue'
import UserRoutes from './Children/UserRoutes/UserRoutes.vue'
import UserStatus from './Children/UserStatus/UserStatus.vue'
import CasualUsers from './Children/CasualUsers/CasualUsers.vue'

//Component files
import { startDataSingleUser } from './SingleUser'

export default {
  name: 'SingleUser',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startDataSingleUser)),
    }
  },
  components: {
    Personal,
    BasicInformation,
    UserCommunications,
    HeaderAndButtons,
    UserRoutes,
    UserStatus,
    CasualUsers,
    EditDetails,
  },
  watch: {
    profile: {
      //every time the profile is changed run check
      handler: function (n) {
        this.checkUser()
      },
      deep: true,
    },
    fullName: {
      handler: function (n, o) {
        this.checkUser()
        if (this.actionType === 'add' && n && o) {
          this.profile.name = n.firstName + ' ' + n.lastName
        }
      },
      deep: true,
    },
    isEmailUniqe() {
      this.checkUser()
    },
    accountType: {
      handler: function (n) {
        if (n === 'Individual') this.profile.role = 'Individual'
        else if (n === 'Transfer') this.profile.role = 'User'
        else if (n === 'Management' && this.profile.role !== 'SuperAdmin')
          this.profile.role = 'Admin'

        this.checkUser()
      },
    },
    'profile.defaultRoute': {
      handler: function (n, o) {
        if (n === undefined && o) this.profile.defaultRoute = null
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentOrganization',
      'loggedUser',
      'currentUser',
      'userRoutes',
      'isProcessing',
      'users',
    ]),

    isShowCasualUsers() {
      if (this.profile.role === 'SuperAdmin') return false
      if (this.profile.role === 'Individual') return false
      else if (this.profile.license === 'API') return false
      else if (!this.currentOrganization.casualUsersDefinitions) return false
      else if (!this.profile.license) return false
      else if (!this.currentOrganization.casualUsersDefinitions.isActive)
        return false
      return true
    },

    isFieldDisabled() {
      if (this.profile.status !== 0) return true
      else if (
        this.currentOrganization &&
        this.currentOrganization.status !== 0
      )
        return true
      return false
    },
    isShowChangeDetails() {
      if (this.accountType === 'add') return false
      else if (this.profile.createUpdateDetails.updatedByName) return true
      else if (this.profile.createUpdateDetails.createdByName) return true
      return false
    },

    isShowVolume() {
      if (this.profile.role === 'SuperAdmin') return false
      else if (this.profile.role === 'Individual') return false
      return true
    },
    isShowUserRoutesComponent() {
      if (this.actionType === 'add') return false
      else if (this.profile.role === 'SuperAdmin') return false
      else if (this.profile.role === 'Individual') return false
      return true
    },
  },
  methods: {
    ...mapActions([
      'EDIT_USER',
      'GET_SINGLE_USER',
      'GET_USER_ROUTES',
      'ADD_USER',
      'GET_SINGLE_ORGANIZATION',
    ]),
    ...mapMutations([
      'SET_NOTIFICATION',
      'SET_IS_IN_MIDDLE_OF_EDITING',
      'SET_USER',
      'SET_PROCESSING',
    ]),

    checkUser() {
      // THIS FUNCTION CHECK BEFORE CILCK ON THE SAVE BUTTON
      this.isSaveButtonDisabled = true
      // disabled
      if (this.actionType === 'edit') {
        if (
          this.profile.status > 0 &&
          this.freezedProfile.status === this.profile.status
        ) {
          this.message = ''
          return
        }
      }

      //if the host is not active
      if (this.currentOrganization.status > 0) {
        this.message = `Cannot ${this.actionType} user in ${
          this.currentOrganization.status === 1 ? 'blocked' : 'archived'
        } host`
        return
      }

      const jsonFreezed = JSON.stringify(this.freezedProfile)
      const jsonProfile = JSON.stringify(this.profile)

      //if there are changes do this

      if (this.actionType === 'edit' && jsonFreezed !== jsonProfile) {
        this.SET_IS_IN_MIDDLE_OF_EDITING(true)
      }

      //if this is add
      if (this.actionType === 'add') {
        this.SET_IS_IN_MIDDLE_OF_EDITING(true)
      }

      //if there are no changes
      if (this.actionType === 'edit' && jsonFreezed === jsonProfile) {
        this.message = ''

        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        return
      }

      //if the super admin want to add a user from users and the new user is need to be part
      // of organization and this is an edit
      else if (
        this.isFromUsers &&
        this.actionType === 'add' &&
        ((!this.profile.organizationId && this.accountType === 'Transfer') ||
          this.profile.role === 'Admin')
      ) {
        this.message = 'Host is required'
        return
      }

      //if tere is no account category :
      else if (!this.accountType) {
        this.message = 'Account category is required'
        return
      }

      // if there is no role
      else if (!this.profile.role) {
        this.message = 'Role is needed'
        return
      }

      //if there is no name on edit
      else if (this.actionType === 'edit' && !this.profile.name.trim()) {
        this.message = 'Name is required'
        return
      }

      //if there is no first name in add
      else if (this.actionType === 'add' && !this.fullName.firstName.trim()) {
        this.message = 'First name is required'
        return
      }
      //if there is no last name in add
      else if (this.actionType === 'add' && !this.fullName.lastName.trim()) {
        this.message = 'Last name is required'
        return
      }

      //if there is no email - and it is not matching the format
      else if (!this.profile.email) {
        this.message = 'Email is required'
        return
      }

      //if invalid email
      else if (!validateEmail(this.profile.email)) {
        this.message = 'Invaild email'
        return
      }

      //if the email is not uniqe
      else if (!this.isEmailUniqe && this.actionType === 'add') {
        this.message = 'Email already taken'
        return
      }

      //if the phone number is invalid
      else if (
        this.profile.phoneNumber &&
        (this.profile.phoneNumber[0] !== '+' ||
          this.profile.phoneNumber.length < 7)
      ) {
        this.message = 'Invalid mobile'
        return
      }

      //when there is not even one interface
      else if (!this.profile.license && this.accountType !== 'Management') {
        this.message = 'License is required'
        return
      }

      //when there is so interface language selected when there is website interface
      else if (
        this.profile.license === 'Website' &&
        !this.profile.language &&
        this.profile.role !== 'SuperAdmin'
      ) {
        this.message = 'Choose portal and email language'
        return
      }

      //Things to add when there is no value in one of the communication ways for the account that is part of organization.
      //need to add here
      else if (
        this.accountType !== 'Individual' &&
        this.profile.role !== 'SuperAdmin' &&
        (this.profile.sendingMethodForSelfResetPassword == 0 ||
          this.profile.sendingMethodForAdminResetPassword == 0)
      ) {
        this.message = 'Sending method is required'
        return
      }

      //if there is two step verfication and there is no value in the user communication
      else if (
        this.profile.isTwoStepVerification &&
        this.profile.sendingMethodForTwoStepVerificationCode === 0
      ) {
        this.message = 'two-step verfication communication method is required'
        return
      }

      //==========CASUALS
      if (
        this.currentOrganization.casualUsersDefinitions &&
        this.currentOrganization.casualUsersDefinitions.isActive
      ) {
        //if there is no casual users licenses
        if (this.profile.numOfAllowedCasuals === '') {
          this.message = 'Casual users license number is required'
          return
        }
      }

      this.message = ''
      this.isSaveButtonDisabled = false
      return
    },
    async checkBeforeSaveRequireAlert() {
      // if the status changed
      if (this.freezedProfile.status !== this.profile.status) {
        const status = this.profile.status === 0 ? 'activate' : 'block'

        const text = `Are you sure you want to ${status} this user?`

        const isUserSure = await confirmDialog(
          this,
          text,
          'Continue',
          'Cancel',
          v => v
        )
        if (!isUserSure) return
        return await this.saveProfile()
      }

      // if the role changed
      if (
        this.freezedProfile.role !== this.profile.role &&
        this.actionType === 'edit'
      ) {
        const text = `You are about to change this account's "account type". Are you sure?`

        const isUserSure = await confirmDialog(
          this,
          text,
          `Change account's type`,
          'Cancel',
          v => v
        )

        if (!isUserSure) return
      }

      // if license changed
      if (
        this.actionType === 'edit' &&
        JSON.stringify(this.freezedProfile.license) !==
          JSON.stringify(this.profile.license)
      ) {
        //the user routes
        if (this.userRoutes.length) {
          const userRoutes = this.userRoutes
            .map(route => {
              if (route.status === 0) {
                return `<li>${route.name}</li>`
              }
            })
            .join(' ')

          const text = `<div><p class="message-class">You are about to change the user's licenses. This action may effect the user's routes:</p> <ul style="margin: 10px">${userRoutes}</ul><p class="message-class">Are you sure you want to save the changes?</p></div>`
          const isUserSure = await confirmDialog(
            this,
            text,
            'Save',
            'Cancel',
            v => v
          )
          if (!isUserSure) return
        }
      }

      // if there is no phone but there are sms's
      if (!this.profile.phoneNumber) {
        const notifications = [
          this.profile.sendingMethodForAwaitingFiles,
          this.profile.sendingMethodForFilesAboutToDelete,
          this.profile.sendingMethodForSelfResetPassword,
          this.profile.sendingMethodForAdminResetPassword,
          this.profile.sendingMethodForTwoStepVerificationCode,
        ]
        const isNotificationHaveSMS = notifications.some(el => el === 2)
        if (isNotificationHaveSMS)
          return await alertDialog(
            this,
            'You are unable to save changes because you deleted the mobile number required for SMS notifications. You can either re-add the mobile number or disable SMS notifications'
          )
      }

      //if the save passed all the levels then save
      return await this.saveProfile()
    },
    async saveProfile() {
      let createdUserId = null
      try {
        this.saveButtonClicked = true
        this.loading = true
        this.profile.email = this.profile.email.toLowerCase()

        //add user
        if (this.actionType === 'add') {
          if (this.profile.role === 'Individual')
            this.profile.organizationId = 0
          this.profile.phoneNumber = this.profile.phoneNumber.replaceAll(
            ' ',
            '-'
          )

          //add the data of the one that created the user
          this.profile.createUpdateDetails = {} //add to emptyuser
          this.profile.createUpdateDetails.createdByUserId =
            this.loggedUser.userId
          this.profile.createUpdateDetails.dateCreatedByMs =
            getUTC0MSDateFromLocalDate()
          createdUserId = await this.ADD_USER(this.profile)
        }

        //edit user
        else {
          this.profile.phoneNumber = this.profile.phoneNumber.replaceAll(
            ' ',
            '-'
          )

          //add the data of the edited user
          if (!this.profile.createUpdateDetails)
            this.profile.createUpdateDetails = {}

          this.profile.createUpdateDetails.updatedByUserId =
            this.loggedUser.userId
          this.profile.createUpdateDetails.dateUpdatedByMs =
            getUTC0MSDateFromLocalDate()

          await this.EDIT_USER({
            userId: this.profile.userId,
            orgId: this.profile.organizationId,
            ...this.profile,
          })

          //if someone edited himself
          if (this.loggedUser.email === this.profile.email) {
            this.SET_USER({ ...this.profile, token: this.loggedUser.token })
          }
        }

        await this.GET_SINGLE_ORGANIZATION(this.profile.organizationId)
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        // this.$router.go(-1)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false

        //when adding a new user after creating return to main page
        if (this.actionType === 'add') {
          //if super admin add a new user
          if (this.loggedUser.role === 'SuperAdmin') {
            //if the super admin came from accounts
            if (this.isFromUsers) this.$router.push(`/management/accounts`)
            //if the super admin came from organization
            else
              this.$router.push(
                `/management/hosts/${this.currentOrganization.organizationId}/registered/users`
              )
          }
          //if host admin add a new user
          else
            this.$router.push(
              `/management/hosts/${this.loggedUser.organizationId}/registered/users`
            )
        }

        //when editing user
        else this.getStartPointSingleUser(createdUserId)
      }
    },

    async getStartPointSingleUser(createdUserId) {
      try {
        //restart the start page
        Object.keys(startDataSingleUser).forEach(key => {
          this[key] = JSON.parse(JSON.stringify(startDataSingleUser))[key]
        })

        this.SET_PROCESSING(true)
        const organizationId = this.$route.params.organizationId
        const userId = createdUserId ? createdUserId : this.$route.params.userId
        //if the host have casual users
        if (
          this.currentOrganization.casualUsersDefinitions &&
          this.currentOrganization.casualUsersDefinitions.isActive
        ) {
          this.casualLicense = this.currentOrganization.allowedLicenses.find(
            el => el.type === 'Casual'
          )
        }
        if (userId === 'new') {
          //if this is a new user
          this.actionType = 'add'
          this.profile = await getEmptyUser()
          if (this.$route.path.includes('hosts')) {
            // if the user if not from usres and from organization
            this.isFromUsers = false
            this.profile.language = this.currentOrganization.language || 'en'
            this.profile.organizationId = Number(organizationId)
            this.profile.role = 'User'
            this.accountType = 'Transfer'
          } else {
            this.profile.role = 'User'
          }
        }
        //if edit
        else {
          this.actionType = 'edit'
          await this.GET_SINGLE_USER(userId || createdUserId)
          await this.GET_USER_ROUTES({ id: userId || createdUserId })

          if (this.$route.path.includes('hosts')) {
            this.isFromUsers = false
          } else {
            await this.GET_SINGLE_ORGANIZATION(this.profile.organizationId)
          }
          this.profile = this.currentUser
          if (this.profile.language === '') {
            if (!this.currentOrganization.language) this.profile.language = 'en'
            else this.profile.language = this.currentOrganization.language
          }

          // getting here the account type
          if (
            this.profile.role === 'SuperAdmin' ||
            this.profile.role === 'Admin'
          ) {
            this.accountType = 'Management'
          } else if (this.profile.role === 'Individual') {
            //if the profile is not part of organization
            this.accountType = 'Individual'
          } else {
            this.accountType = 'Transfer'
          }
        }
        this.freezedProfile = JSON.parse(JSON.stringify(this.profile))

        this.isDataLoaded = true
      } catch (error) {
        console.log(error)
        if (error === 'Can`t fetch user') {
          this.$router.go(-1)
        }
      }

      this.checkUser()

      this.SET_PROCESSING(false)
    },
  },
  async created() {
    this.getStartPointSingleUser()
  },
  async beforeRouteLeave(to, from, next) {
    //THIS WILL RUN IF YOU DIDNT SAVED THE DATA

    if (!this.saveButtonClicked) {
      const jsonFreezed = JSON.stringify(this.freezedProfile)
      const jsonProfile = JSON.stringify(this.profile)

      if (!jsonFreezed) return next()

      if (jsonFreezed !== jsonProfile && to.name !== 'Home') {
        const text =
          'You have unsaved changes.<br>Are you sure you want to leave this page without saving?'

        const thenFunc = () => {
          this.profile = this.freezedProfile
          if (to.path.includes('registered'))
            this.$emit('changeTab', 'tab-registered')
          if (to.path.includes('casual')) this.$emit('changeTab', 'tab-casual')
          if (to.path.includes('settings'))
            this.$emit('changeTab', 'tab-settings')
          next()
        }

        const catchFunc = () => this.$emit('changeTab', 'tab-registered')

        confirmDialog(
          this,
          text,
          'Leave Without Saving',
          'Cancel',
          thenFunc,
          catchFunc
        )
      }
      //if the user quit the system
      else {
        next()
      }
    } else {
      //if the user clicked the save button
      await this.GET_SINGLE_ORGANIZATION(this.profile.organizationId)
      next()
    }
  },
}
</script>

<style scoped src="./SingleUser.css"></style>
