<template>
  <div class="d-flex mb-3">
    <div class="text-field-container">
      <!--INPUT FIELD-->
      <v-text-field
        v-model="organization.name"
        :label="`${isGlobal ? 'Global Name' : 'Host Name (English Only)'} `"
        :class="[actionType === 'add' && 'required']"
        :rules="[
          v => (!!v && !!v.trim()) || 'Host name is required',
          v =>
            /^[A-Za-z0-9\s\-]*$/.test(v) ||
            'Only English letters and numbers are allowed',
        ]"
        :disabled="isHostNameDisabled"
        validate-on-blur
        class="settings-item"
        style="max-width: 220px"
      ></v-text-field>
    </div>

    <!--CHECKBOXES-->
    <div v-if="!isGlobal" class="d-flex">
      <v-checkbox
        v-model="nameShowInArray"
        :disabled="status !== 0"
        value="Website"
        label="Portal"
      ></v-checkbox>
      <v-checkbox
        v-model="nameShowInArray" 
        :disabled="status !== 0"
        value="Email"
        label="Email"
        class="ml-4"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkboxChanged } from '../../ThemeSettings'

export default {
  name: 'HostName',
  data() {
    return {
      nameShowInArray: [],
    }
  },
  props: {
    organization: Object,
    status: Number,
    isGlobal: Boolean,
    actionType: String,
  },
  watch: {
    nameShowInArray: function (n) {
      this.organization.theme.nameInterface = checkboxChanged(n)
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isHostNameDisabled() {
      if (this.isGlobal) return true
      else if (this.loggedUser.role !== 'SuperAdmin') return true
      else if (this.status !== 0) return true
      return false
    },
  },
  created() {
    if (this.organization.theme.nameInterface === 1) {
      this.nameShowInArray.push('Website')
    } else if (this.organization.theme.nameInterface === 2) {
      this.nameShowInArray.push('Email')
    } else if (this.organization.theme.nameInterface === 3) {
      this.nameShowInArray.push('Website')
      this.nameShowInArray.push('Email')
    }
  },
}
</script>

<style scoped src="../ThemeSettingsChildren.css"></style>
