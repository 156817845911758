<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        SFTP Access Details
      </v-card-title>
      <div>
        <div class="select-senders">
          <v-card-text class="px-2 text-h6">Path to:</v-card-text>
          <v-autocomplete
            v-if="isTarget && route.foldersHierarchy.isSenderEmail"
            v-model="sender"
            :items="route.sourceEndPoints"
            placeholder="All Senders"
            return-object
            hide-details
            item-text="userEmail"
          >
            <template v-slot:selection="{ item }">
              <span class="pill">
                {{ item.userName }}
              </span>
            </template>

            <template v-slot:item="{ item, attrs }">
              <v-list-item-avatar>
                <v-simple-checkbox
                  :value="attrs.inputValue"
                  hide-details
                  class="ma-0 pa-0"
                  color="primary"
                >
                </v-simple-checkbox>
              </v-list-item-avatar>
              <v-list-item-content class="dir-ltr">
                <div class="div-click-fixer"></div>
                <div class="div-click-fixer-left"></div>
                <v-list-item-title v-html="item.userEmail"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.userName"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider class="mb-4" />
      </div>
      <v-card-text class="px-2 pb-1"> Protocol: SFTP </v-card-text>
      <v-card-text class="px-2 pb-1"> Host: {{ computedHost }} </v-card-text>
      <v-card-text class="px-2 pb-1"> Port: 22 </v-card-text>
      <v-card-text class="px-2 pb-1">
        Username: {{ computedUsername }}
      </v-card-text>
      <v-card-text class="px-2 pb-1">
        Password: Your dotEngines password
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
        <Button
          :clickAction="closeDialog"
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
        />
        <Button
          :clickAction="sendEmailClicked"
          width="fit-content"
          btnType="blue"
          class="mx-2"
          text="Send Email"
        />
      </div>
    </v-card>
    <InsertEmailDialog
      v-if="isShowInsertDialog"
      :closeDialog="() => (isShowInsertDialog = false)"
      :routeName="route.name"
      :sftpUsername="computedUsername"
      :isTarget="isTarget"
    />
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import InsertEmailDialog from './Children/InsertEmailDialog.vue'

export default {
  name: 'SftpDetailsDialog',
  data() {
    return {
      dialog: true,
      sender: null,
      isShowInsertDialog: false,
    }
  },
  props: {
   
    isTarget: Boolean,
    userEmail:  String,
    route: Object,
    closeDialog: Function,
  },
  components: { Button, InsertEmailDialog },

  computed: {
    computedHost() {
      return window.location.host
    },
    computedUsername() {
      return this.isTarget
        ? `${this.userEmail}${
            this.route.foldersHierarchy?.isPath
              ? '\\' +
                this.route.foldersHierarchy.path +
                (this.sender ? '\\' + this.sender.userEmail : '')
              : ''
          }`
        : this.userEmail + ',' + this.route.name
    },
  },
  methods: {
    sendEmailClicked() {
      this.isShowInsertDialog = true
    },
  },
  created() {
    console.log(this.route)
  },
}
</script>

<style scoped>
.select-senders {
  padding: 0px 8px 7px 0px;
  display: flex;
  align-items: center;
}
</style>
