<template>
  <div>
    <div class="d-flex justify-end mb-2">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="isShowPasteDialog = true"
            aria-label="Paste Emails"
            v-on="on"
            outlined
            color="#616161"
            class="small-btn bg-white mx-1"
            text
            depressed
            :disabled="getIsInMiddleOfFilteingData"
          >
            <v-icon>mdi-content-paste</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Paste Emails') }}</span>
      </v-tooltip>
    </div>

    <v-autocomplete
      v-model="session.selectedCasualUsers"
      :items="loggedUser.casualUsers"
      :search-input.sync="search"
      item-value="email"
      item-text="email"
      chips
      dense
      multiple
      outlined
      small-chips
      hide-details
      :disabled="getIsInMiddleOfFilteingData"
      :label="$t('Guest Recipients')"
    >
      <v-list-item slot="prepend-item" class="select-all-wrapper" @click="selectAllClicked">
        <v-list-item-avatar>
          <v-simple-checkbox
            v-model="isSelectAll"
            hide-details
            class="ma-0 pa-0"
            color="primary"
          >
          </v-simple-checkbox>
        </v-list-item-avatar>
        <v-list-item-content class="dir-ltr">
          <div class="div-click-fixer"></div>
          <div class="div-click-fixer-left"></div>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-slot:item="{ item, attrs }">
        <v-list-item-avatar>
          <v-simple-checkbox
            :value="attrs.inputValue"
            hide-details
            class="ma-0 pa-0"
            color="primary"
          >
          </v-simple-checkbox>
        </v-list-item-avatar>
        <v-list-item-content class="dir-ltr">
          <div class="div-click-fixer"></div>
          <div class="div-click-fixer-left"></div>
          <v-list-item-title v-html="item.email"></v-list-item-title>
          <v-list-item-subtitle v-html="item.name"></v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template v-slot:selection="{ item }">
        <span class="email-pill">
          {{ item.name }}
        </span>
      </template>
    </v-autocomplete>

    <PasteEmailsDialog
      v-if="isShowPasteDialog"
      :closeDialog="() => (isShowPasteDialog = false)"
      :session="session"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PasteEmailsDialog from './Children/PasteEmailsDialog.vue'
export default {
  name: 'CasualAutocomplete',
  data() {
    return {
      isShowPasteDialog: false,
      isSelectAll: false,
      search: '',
    }
  },
  props: {
    session: Object,
  },

  watch: {
    'session.selectedCasualUsers': {
      handler() {
        this.search = ''
      },
      deep: true,
    },
  },
  components: { PasteEmailsDialog },
  computed: {
    ...mapGetters(['loggedUser', 'getIsInMiddleOfFilteingData']),
  },
  methods: {
    selectAllClicked(){
      this.isSelectAll = !this.isSelectAll
      if (this.isSelectAll) {
        this.session.selectedCasualUsers = this.loggedUser.casualUsers
      }
      else {
        this.session.selectedCasualUsers = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.email-pill {
  background-color: #e3e3e3;
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 4px;
}

.select-all-wrapper {
  cursor: pointer; 
}
.select-all-wrapper:hover {
  background-color: #F6F6F6; 
}
</style>
