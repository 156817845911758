<template>
  <div class="pa-2">
    <h4 class="settings-h4">Registered Account Password Requirements</h4>
    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <!--MIN LENGTH PASSWORD-->
      <div class="d-flex align-center mb-2">
        <span class="label" :class="[actionType === 'add' && 'required-field']"
          >Minimum characters:</span
        >
        <v-text-field
          v-model.number="organization.passwordRequirements.minLengthPassword"
          :rules="passwordRules"
          hide-details
          type="number"
          height="16px"
          dense
          class="mw-30 pa-0 ma-0 mx-2 centered-input"
        >
        </v-text-field>
      </div>

      <span class="label">Require:</span>
      <!--NUMBERS REQUIRED-->
      <v-checkbox
        v-model="organization.passwordRequirements.isRequiredNumbers"
        hide-details
        disabled
        :value="true"
        label="Numbers"
        class="mt-0 mw-200"
      >
      </v-checkbox>

      <!--REQUIRED LOWERCASE-->
      <v-checkbox
        v-model="organization.passwordRequirements.isRequiredLowerCase"
        hide-details
        :organization="organization.status !== 0"
        label="Lowercase letter"
        class="mt-0 mw-200"
      >
      </v-checkbox>

      <!--REQUIRED UPPERCASE-->
      <v-checkbox
        v-model="organization.passwordRequirements.isRequiredUpperCase"
        hide-details
        :organization="organization.status !== 0"
        label="Uppercase letter"
        class="mt-0 mw-200"
      >
      </v-checkbox>

      <!--SYMBOLS REQUIRED-->
      <v-checkbox
        v-model="organization.passwordRequirements.isRequiredSpecialSymbols"
        hide-details
        :organization="organization.status !== 0"
        label="Special symbol"
        class="mt-0 mw-180"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information-outline</v-icon>
            </template>
            <span v-html="specialSymbols"> </span>
          </v-tooltip>
        </template>
      </v-checkbox>

      <OnlineHelp
        page="settings"
        section="passwords"
        title="Password Requirements"
      />
    </div>
  </div>
</template>

<script>
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'PasswordRequirements',
  data() {
    return {
      specialSymbols: ` Special symbols: <br/> ! @ # $ % ^ & * ( ) - _ + = { [ } ] \\ | ; : ' "  < . > / ?`,
    }
  },
  props: {
    organization: Object,
    actionType: String,
  },
  computed: {
    passwordRules() {
      return [
        val => !!val || 'Password is required',
        val => val > 5 || 'Minimum password length is 6',
        val => Number.isInteger(val) || 'Number is not valid',
      ]
    },
  },
  components: { OnlineHelp },
}
</script>
