<template>
  <div class="d-flex justify-space-between mb-5" style="width:210px">
    <!--SUFFIX INPUT-->
    <v-text-field
      v-model="organization.theme.suffix"
      counter
      maxlength="30"
      label="Portal Host URL Suffix"
      :class="[actionType === 'add' && 'required']"
      :disabled="status !== 0"
      :rules="[v => /^[a-zA-Z0-9_.-]*$/.test(v) || 'invalid suffix']"
    >
    </v-text-field>

    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon dense v-on="on">mdi-information-outline</v-icon>
      </template>
      <span> Suffix cannot contain spaces or symbols</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Suffix',
  props: {
    organization: Object,
    status: Number,
    actionType: String,
  },
  watch: {
    'organization.name': {
      handler: function(n) {
        //check if add and if n is only english letters
        if (
          this.actionType === 'add' &&
          n !== undefined &&
          /^[A-Za-z0-9\s\-]*$/.test(n)
        ) {
          this.organization.theme.suffix = n.replace(/\s+/g, '-').toLowerCase()
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped src="../ThemeSettingsChildren.css"></style>
