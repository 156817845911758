<template>
  <div class="d-flex align-center mb-5 ">
    <div class="settings-item">
      <div class="d-flex align-center">
        <!--SELECT COLOR-->
        <v-select
          v-model="organization.theme.themeColor"
          :items="themeColorItems"
          label="Portal Theme Color"
          style="max-width: 105px"
          :disabled="status !== 0 && !isGlobal"
        >
        </v-select>
        <!--CIRCLE SHOW THE SELECTED COLOR-->
        <div
          class="theme-color-dot"
          :style="{ backgroundColor: organization.themeColor }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemeColor',
  data() {
    return {
      themeColorItems: [
        { value: '#f44336', text: 'Red' },
        { value: '#4caf50', text: 'Green' },
        { value: '#1977f3', text: 'Blue' },
        { value: '#000000', text: 'Black' },
        { value: '#6a0dad', text: 'Purple' },
      ],
    }
  },
  props: {
    organization: Object,
    status: Number,
    isGlobal: Boolean,
  },
}
</script>

<style scoped src="./ThemeColor.css"></style>
<style scoped src="../ThemeSettingsChildren.css"></style>
