//===Views===
import Home from '@/views/Home/Home.vue'
import SidebarManagement from '@/views/SidebarManagement/SidebarManagement.vue'
import HostList from '@/views/HostsList/HostList.vue'
import WebsiteInterface from '@/views/WebsiteInterface/WebsiteInterface.vue'
import Policies from '@/views/Policies/Policies.vue'
import SystemLogs from '@/views/SystemLogs/SystemLogs.vue'
import Profile from '@/views/Profile/Profile.vue'
import SingleOrganization from '@/views/SingleOrganization/SingleOrganization.vue'
import Analytics from '@/views/Analytics/Analytics.vue'
import CasualUpload from '@/views/CasualInterface/CasualUpload/CasualUpload.vue'
import CasualDownload from '@/views/CasualInterface/CasualDownload/CasualDownload.vue'

//===Components===
//HOME
import SetNewPassword from '@/components/Home/SetNewPassword/SetNewPassword.vue'
import RegistrationForm from '@/components/Home/RegistrationForm/RegistrationForm.vue'
import GoToFilesLogin from '@/components/Home/Logins/GoToFilesLogin/GoToFilesLogin.vue'

//SINGLE ORGANIZATION
import UsersList from '@/components/SingleOrganization/UsersList/UsersList.vue'
import SingleUser from '@/components/SingleOrganization/SingleUser/SingleUser.vue'
import OrgRoutes from '@/components/SingleOrganization/Routes/OrgRoutes/OrgRoutes.vue'
import SingleRoute from '@/components/SingleOrganization/Routes/SingleRoute/SingleRoute.vue'
import OrganizationSettings from '@/components/SingleOrganization/OrganizationSettings/OrganizationSettings.vue'
import GlobalSettings from '@/components/SingleOrganization/GlobalSettings/GlobalSettings.vue'
import RegisteredUsersTab from '@/components/SingleOrganization/RegisteredUsersTab/RegisteredUsersTab.vue'
import KioskSettings from '@/components/SingleOrganization/KioskSettings/KioskSettings.vue'

//BASE COMPONENTS
import NotFound from '@/components/BaseComponents/NotFound/NotFound.vue'
import GlobalError from '@/components/BaseComponents/GlobalError/GlobalError.vue'
import Block from '@/components/BaseComponents/Block/Block.vue'

//POLICIES
import PolicyEditor from '@/components/Policies/PolicyEditor/PolicyEditor.vue'

//PROFILE
import PersonalProfile from '@/components/Profile/PersonalProfile/PersonalProfile.vue'
import RoutesList from '@/components/Profile/RoutesList/RoutesList.vue'
import SessionHistory from '@/components/Profile/SessionHistory/SessionHistory.vue'
import GuestsSFTP from '@/components/Profile/GuestsSFTP/GuestsSFTP.vue'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/sign',
    redirect: '/',
  },
  {
    path: '/register',
    name: 'RegistrationForm',
    component: RegistrationForm,
  },
  {
    path: '/go-to-files',
    name: 'GoToFiles',
    component: GoToFilesLogin,
  },
  {
    path: '/set-new-password',
    name: 'SetNewPassword',
    component: SetNewPassword,
  },
  {
    path: '/website-interface',
    name: 'WebsiteInterface',
    component: WebsiteInterface,
    meta: {
      roles: [
        'SuperAdmin',
        'Admin',
        'User',
        'Individual',
        'Approver',
        'Individual',
        'GuestUser',
      ],
    },
  },
  {
    path: '/sftp-interface',
    name: 'sftpInterface',
    component: WebsiteInterface,
    meta: {
      roles: [
        'SuperAdmin',
        'Admin',
        'User',
        'Individual',
        'Approver',
        'Individual',
        'GuestUser',
      ],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      roles: ['SuperAdmin', 'Admin', 'User', 'Individual', 'GuestUser'],
    },
    children: [
      { name: 'PersonalProfile', path: 'personal', component: PersonalProfile },
      { name: 'Routes', path: 'routes', component: RoutesList },
      { name: 'SessionHistory', path: 'history', component: SessionHistory },
      { name: 'GuestsSFTP', path: 'guests', component: GuestsSFTP },
    ],
  },

  //CASUAL ROUTES
  {
    path: '/casual-upload',
    name: 'CasualUpload',
    component: CasualUpload,
  },

  {
    path: '/casual-download',
    name: 'CasualDownload',
    component: CasualDownload,
  },

  {
    path: '/management',
    name: 'management',
    component: SidebarManagement,
    redirect: '/management/hosts',
    children: [
      {
        path: 'logs',
        component: SystemLogs,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },

      {
        path: 'analytics',
        component: Analytics,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },

      {
        path: 'accounts',
        component: UsersList,
        meta: {
          roles: ['SuperAdmin', 'Admin', 'Approver'],
        },
      },
      {
        path: 'accounts/:userId',
        component: SingleUser,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },
      {
        name: 'SingleRoute',
        path: 'routes/:routeid',
        component: SingleRoute,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },
      {
        path: 'globalSettings',
        component: GlobalSettings,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },
      {
        path: 'hosts',
        component: HostList,
        meta: {
          roles: ['SuperAdmin', 'Admin'],
        },
      },
      {
        path: 'hosts/new',
        component: OrganizationSettings,
      },

      //SINGLE ORGANIZATION PATHES
      {
        path: 'hosts/:organizationId',
        component: SingleOrganization,
        redirect: 'hosts/:organizationId/registered',
        meta: {
          roles: ['SuperAdmin', 'Admin', 'Approver'],
        },
        children: [
          {
            path: 'registered',
            component: RegisteredUsersTab,
            children: [
              {
                path: 'users',
                component: UsersList,
                meta: {
                  roles: ['SuperAdmin', 'Admin'],
                },
              },

              {
                path: 'users/:userId',
                component: SingleUser,
                meta: {
                  roles: ['SuperAdmin', 'Admin'],
                },
              },

              {
                path: 'routes',
                component: OrgRoutes,
                meta: {
                  roles: ['SuperAdmin', 'Admin'],
                },
              },
              {
                path: 'routes/:routeId',
                component: SingleRoute,
                meta: {
                  roles: ['SuperAdmin', 'Admin'],
                },
              },
            ],
          },

          {
            name: 'Kiosks',
            path: 'kiosks',
            component: KioskSettings,
            meta: {
              roles: ['SuperAdmin', 'Admin'],
            },
          },

          {
            path: 'settings',
            component: OrganizationSettings,
            meta: {
              roles: ['SuperAdmin', 'Admin'],
            },
          },
        ],
      },
      {
        path: 'policies',
        name: 'Policies',
        component: Policies,
      },
      {
        path: 'policies/:policy',
        name: 'PolicyEditor',
        component: PolicyEditor,
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('@/views/HistoryLogs/HistoryLogs.vue'),
      },
    ],
  },

  {
    path: '/blocked',
    name: 'Block',
    component: Block,
    meta: {
      roles: ['SuperAdmin', 'Admin', 'User', 'Individual'],
    },
  },
  {
    path: '/error',
    name: 'GlobalError',
    component: GlobalError,
    props: true,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
]
