<template>
  <div
    id="registered-users"
    class="registered-users-wrapper"
    :class="[type !== 'mobile' && 'pos-relative']"
  >
    <v-autocomplete
      v-model="session.selectedUsers"
      @change="lightSessionRestart"
      :items="computedUsersList"
      :error="errors.isRegError"
      :disabled="isInMiddleOfFiltering"
      :rules="[v => !!v || 'At least one recipient is required']"
      :label="$t('Registered Recipients')"
      item-value="userEmail"
      item-text="userEmail"
      multiple
      outlined
      dense
    >
      <!--MAKE THE SELECTION -->
      <template v-slot:selection="{ item }">
        <span class="pill">{{ item.userName || item.userEmail }}</span>
        <!-- <span v-if="index === 0" class="light-text mx-1">
          <span
            v-if="
              session.selectedUsers.length === 1 &&
              computedUsersList.length === 1
            "
            class="fs-1r"
          >
            {{ session.selectedUsers[0] }}
          </span>

          <span
            v-else-if="
              session.selectedUsers.length === computedUsersList.length
            "
            class="fs-1r"
          >
            {{ $t('All selected') }} ({{ session.selectedUsers.length }})
          </span>
          <span v-else class="fs-1r mx-1">
            {{ session.selectedUsers.length }} {{ $t('of') }}
            {{ computedUsersList.length }} {{ $t('selected') }}
          </span>
        </span> -->
      </template>

      <!--TO SELECT ALL-->
      <template v-slot:prepend-item>
        <v-list-item @mousedown.prevent @click="selectAllOrSomeUserClicked">
          <v-list-item-action>
            <v-icon
              style="margin-left: 2px !important"
              :color="session.selectedUsers.length > 0 ? 'primary' : ''"
            >
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('Select all') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-slot:item="{ item, attrs }">
        <v-list-item-avatar>
          <v-simple-checkbox
            :value="attrs.inputValue"
            hide-details
            class="ma-0 pa-0"
            color="primary"
          >
          </v-simple-checkbox>
        </v-list-item-avatar>
        <v-list-item-content class="dir-ltr">
          <div class="div-click-fixer"></div>
          <div class="div-click-fixer-left"></div>
          <v-list-item-title v-html="item.userEmail"></v-list-item-title>
          <v-list-item-subtitle v-html="item.userName"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RegisteredRecipients',
  props: {
    session: Object,
    errors: Object,
    lightSessionRestart: Function,
    type: String,
  },

  watch: {
    'session.selectedRoute': {
      handler: function (n) {
        if (!n) return
        const activeUsers = []
        //get all the targets that are with status 0
        //and if there is only one then put it on selected registered users
        for (const user of n.targetEndPoints) {
          //get all
          if (user.userStatus === 0) activeUsers.push(user)
          if (activeUsers.length > 1) break
        }
        if (activeUsers.length === 1)
          this.session.selectedUsers.push(activeUsers[0].userEmail)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfFiltering']),
    computedUsersList() {
      let users = []
      //if this is regular user and there is no selected route
      if (!this.session.selectedRoute) return []
      //regular users
      else {
        //this function will check if all the emails provided to select all are uniqe and the users arent blocked or removed
        for (let targetEndPoint of this.session.selectedRoute.targetEndPoints) {
          if (
            !users.includes(targetEndPoint.userEmail) &&
            targetEndPoint.userStatus === 0
          ) {
            users.push({
              userEmail: targetEndPoint.userEmail,
              userName: targetEndPoint.userName,
            })
          }
        }
      }
      this.selectUserList = users
      return users.sort((a, b) => {
        if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) return -1
        if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) return 1
        return 0
      })
    },

    //FOR SELECT SOME AND ALL
    isAllUsersSelected() {
      return this.session.selectedUsers.length === this.selectUserList.length
    },
    someUsersSelected() {
      return this.session.selectedUsers.length > 0 && !this.isAllUsersSelected
    },
    icon() {
      if (this.isAllUsersSelected) return 'mdi-close-box'
      if (this.someUsersSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    selectAllOrSomeUserClicked() {
      this.$nextTick(() => {
        if (this.isAllUsersSelected) {
          this.session.selectedUsers = []
        } else {
          this.session.selectedUsers = this.selectUserList.slice()
        }
      })
    },
  },

  created() {
    if (
      this.loggedUser.role === 'Casual' &&
      this.loggedUser.boundedUsers.length === 1
    ) {
      this.session.selectedUsers = [this.loggedUser.boundedUsers[0].email]
    }
  },
}
</script>

<style lang="scss">
.registered-users-wrapper {
  .pill {
    background-color: #e3e3e3;
    padding: 2px 5px;
    border-radius: 10px;
    margin: 2px 4px;
  }
}
</style>
