<template>
  <div class="w-50pr">
    <div class="wrapper-1 pa-3 mb-5 ml-1">
      <div class="d-flex justify-space-between fill-width">
        <h3 class="ma-0 pa-0 mb-1 fs-1-25r">
          Recipients ({{ route.targetEndPoints.length }})
        </h3>
        <!--ALLOW CASUALS IN THIS ROUTE ONLY IF THE ORGANIZATION HAVE EXTENTION-->
        <div class="pos-relative">
          <OnlineHelp
            page="routes"
            section="targetEndPoints"
            title="Recipients"
          />
        </div>
      </div>
      <v-divider class="mb-1" />

      <div class="d-flex mb-3 target-wrapper">
        <v-autocomplete
          v-model="route.targetEndPoints"
          :items="computedTargetUsers"
          :menu-props="customeMenuProps"
          :item-disabled="item => item.userStatus === 1"
          :class="[actionType === 'add' && 'required']"
          :disabled="route.status !== 0 || currentOrganization.status !== 0"
          placeholder="Search"
          multiple
          return-object
          item-text="userEmail"
          hide-details
          single-line
        >
          <template v-slot:selection="{ item }">
            <span class="pill">
              {{ item.userName }}
            </span>
          </template>
          <template v-slot:prepend-inner>
            <v-icon class="mt-1" dense>mdi-magnify</v-icon></template
          >

          <template v-slot:item="{ item, attrs }">
            <v-list-item-avatar>
              <v-simple-checkbox
                :value="attrs.inputValue"
                hide-details
                class="ma-0 pa-0"
                color="primary"
              >
              </v-simple-checkbox>
            </v-list-item-avatar>
            <v-list-item-content class="dir-ltr">
              <div class="div-click-fixer"></div>
              <div class="div-click-fixer-left"></div>
              <v-list-item-title v-html="item.userEmail"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.userName"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div v-if="route.targetEndPoints && route.targetEndPoints.length">
        <div
          v-for="userEndPoint in route.targetEndPoints"
          :key="userEndPoint.userId + 't'"
        >
          <SourceOrTargetItem
            type="target"
            :userEndPoint="userEndPoint"
            :routeStatus="route.status"
            :curOrgStatus="currentOrganization.status"
            :actionType="actionType"
            :isEditDisabled="isEditDisabled"
            :route="route"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base compoentns
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

//"Base component" - this component is also shown in target end point
import SourceOrTargetItem from '../SourceOrTargetItem/SourceOrTargetItem.vue'

export default {
  name: 'TargetEndPoints',
  props: {
    route: Object,
    computedUsers: Array,
    isEditDisabled: Boolean,
    actionType: String,
  },
  components: { SourceOrTargetItem, OnlineHelp },

  computed: {
    ...mapGetters(['currentOrganization']),
    customeMenuProps() {
      const { height } = this.$vuetify.breakpoint
      if (height <= 750) return {}
      else if (height > 750 && height < 900) return { maxHeight: '400px' }
      return { maxHeight: '500px' }
    },
    computedTargetUsers() {
      const targets = JSON.parse(JSON.stringify(this.computedUsers))
      return targets
        .filter(el => el.license)
        .sort((a, b) => {
          if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) return -1
          if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) return 1
          return 0
        })
    },
  },
}
</script>

<style lang="scss">
.target-wrapper {
  .pill {
    background-color: #e3e3e3;
    padding: 2px 5px;
    border-radius: 10px;
    margin: 2px 4px;
  }

}
</style>
