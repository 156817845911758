<template>
  <div class="d-flex flex-column align-center">
    <Button
      btnType="grey"
      :text="$t('Open Guests Phone Book')"
      :clickAction="openCasualsPhoneBook"
      class="my-4"
    />

    <div class="fill-width">
      <CasualAutocomplete :session="session" class="my-2" />
      <Subject
        :session="session"
        :lightSessionRestart="() => null"
        class="my-2"
      />

      <Message
        :session="session"
        :lightSessionRestart="() => null"
        class="my-2"
      />

      <!--BUTTONS TO UPLOAD FILE/FOLDER-->
      <div class="wrapper-1 my-3 mx-1">
        <FileDirButtons
          class=""
          :session="session"
          :lightSessionRestart="() => null"
        />
      </div>
    </div>

    <CasualsPhoneBook
      v-if="isShowCasualsPhoneBook"
      :closeDialog="() => (isShowCasualsPhoneBook = false)"
      :session="session"
    />
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import CasualsPhoneBook from './Children/CasualsPhoneBook/CasualsPhoneBook.vue'
import CasualTextareaInput from './Children/CasualTextareaInput/CasualTextareaInput.vue'
import CasualAutocomplete from './Children/CasualAutocomplete/CasualAutocomplete.vue'
import Subject from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/Childrens/Subject/Subject.vue'
import Message from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/Childrens/Message/Message.vue'
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'

export default {
  name: 'CasualSend',
  data() {
    return {
      isShowCasualsPhoneBook: false,
    }
  },
  components: {
    Button,
    CasualsPhoneBook,
    CasualTextareaInput,
    CasualAutocomplete,
    Subject,
    Message,
    FileDirButtons,
  },
  props: {
    session: Object,
  },
  methods: {
    openCasualsPhoneBook() {
      this.isShowCasualsPhoneBook = true
    },
  },
}
</script>
