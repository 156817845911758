<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Status</h4>
    <div class="wrapper-2 pa-3 d-flex justify-space-between pos-relative">
      <v-radio-group
        v-model="profile.status"
        :disabled="isStatusChangeDisabled"
        dense
        hide-details
        class="ma-0"
        row
      >
        <v-radio
          class="ma-0 mr-4"
          :value="0"
          label="Active"
          :disabled="isActiveRadioDisabled"
        >
        </v-radio>

        <v-radio class="ma-0 mr-4" label="Blocked (Temporary)" :value="1">
        </v-radio>
      </v-radio-group>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="removeUser"
            :disabled="loggedUser.userId === profile.userId"
            v-on="on"
            x-small
            rounded
            outlined
            fab
            color="error"
            class="mr-6"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete user</span>
      </v-tooltip>
      <OnlineHelp
        page="singleUser"
        section="userStatus"
        title="Account Status"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { alertDialog, confirmDialog } from '@/utils'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'UserStatus',
  props: {
    profile: Object,
    freezedProfile: Object,
  },
  components: { OnlineHelp },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'userRoutes']),
    isStatusChangeDisabled() {
      if (this.loggedUser.userId === this.profile.userId) return true
      else if (this.freezedProfile.status === 2) return true
      else if (this.currentOrganization.status !== 0) return true
      return false
    },
    isActiveRadioDisabled() {
      // if user is archvied
      if (this.profile.status === 0) return false
      if (this.freezedProfile.status === 2) return true
      //this function finds the license and check if the organization can active the user
      const licenses = ['SFTP', 'Cloud', 'API', 'Website']
      for (let licenseName of licenses) {
        if (this.profile.license === licenseName) {
          const license = this.currentOrganization.allowedLicenses.find(
            el => el.type === licenseName
          )
          if (license.numOfAllowed < license.numOfInUse + 1) return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions(['REMOVE_USER']),
    removeUser() {
      //if the user have active routes
      if (this.userRoutes.length)
        return alertDialog(
          this,
          'Cannot delete this user as they are associated with active routes. Please delete them from the routes before proceeding with deletion.'
        )

      const text = 'Are you sure you want to delete this user?'

      const thenFunc = async () => {
        await this.REMOVE_USER(this.profile)

        //when super admin deletes the user send him to the org
        if (
          this.loggedUser.role === 'SuperAdmin' &&
          !this.$route.path.includes('accounts')
        ) {
          this.$router.push(
            `/management/hosts/${this.profile.organizationId}/registered/users`
          )
        }

        //if super admin deletes from users page
        else if (
          this.loggedUser.role === 'SuperAdmin' &&
          this.$route.path.includes('accounts')
        )
          this.$router.push('/management/accounts')
        //if host admin deletes:
        else {
          this.$router.push('/')
        }
      }
      const catchFunc = e => {
        if (e !== false) this.$router.push('/')
      }

      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc, catchFunc)
    },
  },
}
</script>
