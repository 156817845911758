<template>
  <div class="d-flex align-self-center mb-5">
    <div class="text-field-container">
      <!--HEADER INPUT -->
      <v-text-field
        v-model="organization.theme.headerTextValue"
        :disabled="status !== 0 && !isGlobal"
        placeholder=" "
        class="settings-item"
        label="Portal Slogan"
      ></v-text-field>
    </div>

    <!--CHECKBOXES-->
    <div v-if="!isGlobal" class="d-flex">
      <v-checkbox
        v-model="headerTextShowInArray"
        :disabled="status !== 0"
        value="Website"
        label="Portal"
      ></v-checkbox>
      <v-checkbox
        v-model="headerTextShowInArray"
        :disabled="status !== 0"
        value="Email"
        label="Email"
        class="ml-4"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { checkboxChanged } from '../../ThemeSettings'

export default {
  name: 'HeaderText',
  data() {
    return { headerTextShowInArray: [] }
  },
  props: { organization: Object, status: Number, isGlobal: Boolean },
  watch: {
    headerTextShowInArray: function (n) {
      this.organization.theme.headerTextInterface = checkboxChanged(n)
    },
  },
  created() {
    if (this.organization.theme.headerTextInterface === 1) {
      this.headerTextShowInArray.push('Website')
    } else if (this.organization.theme.headerTextInterface === 2) {
      this.headerTextShowInArray.push('Email')
    } else if (this.organization.theme.headerTextInterface === 3) {
      this.headerTextShowInArray.push('Website')
      this.headerTextShowInArray.push('Email')
    }
  },
}
</script>

<style scoped src="../ThemeSettingsChildren.css"></style>
