export const onlineHelpData = {
  singleUser: {
    account: {
      Admin: `
      <ul>
      <li>
        <p>Account category:</p>

        <p>
          <b><u>Transfer:</u></b> the account is only allowed to transfer files (send and/or receive
          files).
        </p>

        <p>
        <b><u>Management:</u></b
        ><br />

          <b>Host admin:</b> the account is allowed to transfer files and manage
          the host – add, edit, and delete accounts and routes, manage the
          settings and filtering policy, view logs and release files from
          quarantine.
          <br/>
          <b>Super admin:</b> the account is allowed to create manage and delete all hosts and accounts, 
          including other super admins, guest users and individuals. 
          Super admins will allocate licenses to the use of the different hosts. 
          Super admins can host's system logs but they do not have access to the hosts' policies and filtering logs. 
          Super admins can filter and download files only for personal use and will not be able to transfer files to other users. 
          Super admins can create and edit a list of policies which they share with other super admins and individual users.
          <br/>
          <b>Individuals:</b> the account is allowed to filter and download files 
          only for personal use and will not be able to transfer files to other users. 
          Individuals share a list of policies with other super admins and individual users. 

        </p>
      
      </li>

      <li>
        <p>License:</p>

        <p>
          Select from the list of available licenses, what interface will be
          available when creating filtering routes for the account.
        </p>

        <p>
          Selecting the license is mandatory for transfer users and only one
          license is allowed per account.
        </p>

        <p>
          Once allocated, a license cannot be changed. Each user only has one
          license.
        </p>
      </li>

      <li>
        <p>Report:</p>

        <p>
          Select whether to show the sender/recipient the filtering results and
          status of the files. It might be helpful for the user to see if some
          of his files were blocked or modified and contact the host admin for
          further investigation.
        </p>
      </li>

      <li>
        <p>Details level:</p>
        <p>
          <b>All:</b> show a full report with all the details. <br />
          <b>Modified:</b> show just the modified files. Note that some
          modifications may not be visible, like rebuilding of images and
          multimedia files. <br />
          <b>Partial:</b> show only files that some of their internal files were
          blocked, such as an archive file that contains many files that some of
          them are forbidden. <br />
          <b>Rejected:</b> show only files that were rejected. <br />
        </p>
      </li>

      <li>
        <p>Portal & Email Language:</p>

        <p>
          Select the language for the transfer interfaces from the list of
          available languages.
        </p>

        <p>
          Note that all management screens including the settings, Policy Editor
          end logs will be in English.
        </p>
      </li>
    </ul>`,
      SuperAdmin: `
      <ul>
      <li>
        <p>Account category:</p>

        <p>
          <b><u>Transfer:</u></b
          ><br />

          the account is only allowed to transfer files (send and/or receive
          files).
        </p>

        <b><u>Management:</u></b
        ><br />

        <p>
          <b>Host admin:</b> the account is allowed to transfer files and manage
          the host – add, edit, and delete accounts and routes, manage the
          settings and filtering policy, view logs and release files from
          quarantine.
        </p>

        <p>
          <b>Super admin:</b> the account is allowed to create, manage and
          delete all hosts and accounts, including other super admins, casual
          users and individuals. Super admins will allocate licenses to the use
          of the different hosts. Super admins can see host's system logs but
          they do not have access to the host's policies and filtering logs.
          Super admins can filter and download files only for personal use and
          will not be able to transfer files to other users. Super admins can
          create and edit a list of policies which they share with other super
          admins and individual users.
        </p>
        <p>
          <b>Individuals:</b> the account is allowed to filter and download
          files only for personal use and will not be able to transfer files to
          other users. Individuals share a list of policies with other super
          admins and individual users.
        </p>
      </li>

      <li>
        <p>License:</p>

        <p>
          Select from the list of available licenses, what interface will be
          available when creating filtering routes for the account.
        </p>

        <p>
          Selecting the license is mandatory for transfer users and only one
          license is allowed per account.
        </p>

        <p>
          Once allocated, a license cannot be changed. Each user only has one
          license.
        </p>
      </li>

      <li>
        <p>Report:</p>

        <p>
          Select whether to show the sender/recipient the filtering results and
          status of the files. It might be helpful for the user to see if some
          of his files were blocked or modified and contact the host admin for
          further investigation.
        </p>
      </li>

      <li>
        <p>Details level:</p>
        <p>
          <b>All:</b> show a full report with all the details. <br />
          <b>Modified:</b> show just the modified files. Note that some
          modifications may not be visible, like rebuilding of images and
          multimedia files. <br />
          <b>Partial:</b> show only files that some of their internal files were
          blocked, such as an archive file that contains many files that some of
          them are forbidden. <br />
          <b>Rejected:</b> show only files that were rejected. <br />
        </p>
      </li>

      <li>
        <p>Portal & Email Language:</p>

        <p>
          Select the language for the transfer interfaces from the list of
          available languages.
        </p>

        <p>
          Note that all management screens including the settings, Policy Editor
          end logs will be in English.
        </p>
      </li>
    </ul>
      `,
    },
    user: `
    <ul>
    <li>
      Insert the information of the user that will be using the account.
    </li>
    <li>
      Make sure to insert a correct and valid email address as it will be used
      for login, verification and receiving notifications.
    </li>
    <li>
      Select if 2-step verification is needed for this user whether by email
      or SMS.
    </li>

    <li>
      If 2-step verification is chosen the user will need to enter, in
      addition to his password, a code he will receive in a notification.
    </li>
  </ul>`,
    userRoutes: `
    <p>
    Show all the routes that are assigned to the account and select a route to be presented to the user as a default.
     The user will not need to choose it from a list, and it will be automatically selected.
      If there is more than one route, choose the one that is most common in use.
    </p>`,
    userStatus: `
    <div>
    <p>Change the status of the account:</p>

    <p>As a default, the account is active.</p>

    <p>The status of the account can be changed to blocked (temporary).</p>

    <p>
      The status of the account can be changed back from blocked to active
      status.
    </p>

    <p>A host admin cannot change its own status.</p>

    <p>Delete the account:</p>

    <p>
      Delete the account permanently. Deleted accounts cannot be restored.
    </p>

    <p>A host admin cannot delete himself.</p>

    <p>An account that has an active route cannot be deleted.</p>
  </div>`,
    casual: `
    <ul>
<li>Select ‘allow guest users’ to allow the registered user to send files to and from guests. </li>
<li>Guest users are not part of the host and are not registered users. </li>
<li>The guests receive a transfer link they can use without having to manage credentials to dotEngines. </li>
<li>Each registered user can have many guests assigned to him, which he can manage with his guest phone book.</li>
<li>Each registered user can produce a link for sending or receiving files and send it to its guests. </li>
<li>The number of registered users that can interact with guests is limited by the issued license, to increase it please contact your system provider. </li>
  </ul>`,
    notifications: `<p>Select how the account will receive different types of notifications, by email, SMS, or none.</p>`,
  },
  casualUsers: {
    casualTable: `
    <div>
      <p>See a list of all active casual users, their details and privileges and
       the registered users they are associated with.</p>
      <p>Choose the 'Edit' bouton to edit the user's details and to associate them with additional registered users.</p>
      <p>Choose the 'Archive' button to archive the user and free the license allocation for a new casual user.</p>
      <p>The user's details will be saved, and he can be 'reactivated' if needed.</p>
      <p>To see the list of archived users and change their status back to active click on 'Show archived casuals'.</p>
    </div>
    `,
    licenses: ` <p>Casual accounts are meant for users registered users who need to transfer files to the registered casual users of the system, but not on a regular basis.
    They receive a transfer link they can use without having to manage credentials to dotEngines. Each registered user can have casual accounts assigned to him.
    The number of casual accounts per host is limited by the issued license, to increase it please contact your system provider. 
    </p>`,
    registeredAccounts: `
    <div>
      <p>Each registered user can have casual accounts assigned to him.</p>
      <p>The table shows how many licenses are used out of the total that is allocated to this user.
       To change the allocation per user, insert the license number in the 'Casual usage' column.
       This can also be managed in the registered user's settings.</p>
      <p>To add a new casual user, click on 'Add casual account' next to the registered user he will be associated with.
       After the user is added he will receive a direct link to transfer files without having to register.</p>
    </div>
    `,
    archivedCasuals: `
    <div>
      <p>The following casual accounts have been archived and have available licenses.</p>
      <p>If there is a need to transfer file to them, the users can be reactivated with all their details.</p>
      <p>To change an account's status from 'archived' to 'active' click on 'Activate account'.</p>
      <p>Select a registered account from the 'Casual account parent' list.</p>
      <p>The casual account will be associated with it. </p>
      <p>To delete an account from the archived list, click on 'Activate account'.</p>
    </div>
    `,
  },
  routes: {
    routeSettings: `
      <p>Select the setting of the route. Make sure to create or import at least one policy and add at least one account before creating a route.</p>`,
    advancedSettings: `
    <div>
      <p>
        <b><u>Directory structure: </u></b>
      </p>

      <p>
        Select the directory structure the files will be saved in after transfer
        and filtering. More than one option can be chosen. Example: the files
        will be saved in a directory name as the sender's email and in it will
        be in a sub directory with the timestamp.
      </p>

      <p>
        <b>Sender email:</b>
      </p>

      <p>
        The files will be served in a directory named as the sender's email.
      </p>

      <p><b>Prepend path: </b></p>

      <p>
        Manually insert a path that the files will be saved under, such as:
        Files from bank/Loans.
      </p>

      <p>
        <b>Session:</b>
      </p>

      <p>
        Each session of files will be saved in a designated directory. The name
        of the directory will be the timestamp (date and time that the files
        were sent in).
      </p>

      <p>
        <b><u> Awaiting files: </u></b>
      </p>

      <p>
        Select the time after which the files will be automatically deleted in
        days or in hours. The maximum lifespan of files is seven days, to save
        files beyond seven days contact your system provider. The users will be
        able to delete the files themselves at any point during that time.
      </p>

      <p>
        Select when to alert the file owner before his files will be deleted.
        The default is 2 days before deletion. The alert will be sent by email
        or sms accourding to the account's alerts settings.
      </p>

      <p>
        <b><u>Files collision:</u></b>
      </p>

      <p>
        Select what to do in case a file with the same name already exists in
        the target directory.
      </p>

      <p>Overwrite: save the new file and discard the old.</p>

      <p>
        Add timestamp: keep both files and add a timestamp to the name of the
        new file.
      </p>
    </div>`,
    targetEndPoints: `
    <div>
      <p>Select the accounts that will be receiving files with this route.</p>
      <p>The account's email addresses appear in alphabetical order. </p>
      <p>Start typing the email address to find it faster.</p>
      <p>Interface: shows the interface of the selected account. An account can only have one interface</p>
    </div>
    `,
    sourceEndPoints: `
    <div>
      <p>Select the accounts that will be sending files with this route.</p>
      <p>The account's email addresses appear in alphabetical order.</p>
      <p>Start typing the email address to find it faster.</p>
      <p>Show progress: select whether to present the sender the progress of the file sending and filtering.
       This will help him to monitor and understand the different stages the files go through.</p>
    </div>
    `,
    routeStatus: `
    <div>
      <p><b>Status<b/></p>
      <p>Change the status of the route:</p>
      <p>As a default, the route is active.</p>
      <p>The status of the route can be changed to blocked (temporary) to prevent the user from using the route.</p>
      <p><b>Delete Route<b/></p>
      <p>Delete the route permanently. Deleted routes cannot be restored.</p>
    </div>
    `,
  },
  settings: {
    design: `
    <div>
      <p>Set design settings for your host, such as logo, background image and header text. </p>
      <p>For each design setting, select if you want to present it in the emails, in the website interface or both.</p>
      <p>Append the suffix to the URL address to see the design settings, example: <br /> https://dotengines.com/?suffix=companyname </p>
      <p>The recommended background image resolution is 1920 x 1080.</p>
    </div>`,
    licenses: {
      Admin: `
      <p>See the status and usage of the licenses that are available to the host. You can allocate the available licenses to the account in account settings. To change the amount of the allocated account please contact your system provider.</p>
      `,
      SuperAdmin: `
      <div>
        <p>Mange the licenses of the host.</p>
        <p>You can view and edit the amount of licenses that is allocated and used by the host per interface.</p>
        <p>delete or add licenses as needed.</p>
      </div>`,
    },
    policies: ` 
    <div>
      <p>Import a policy to be used by your host or create a new policy. (polx file)</p>
      <p>Contact your system provider for training on how to create and edit a policy.</p>
      <p>Open the policies table to see and edit a list of all the policies that are associated with your host.</p>
    </div>`,
    casualSettings: `
    <ul>
    <li>Select 'Two-factor authentication' to verify the user's identity with two forms of identification. The user will receive an email with a link to the system, in addition he will need to provide a code which will be sent to his mobile by SMS. </li>
<li>Select the time zone offset (in hours or in minutes) that will appear in the timestamp for guests. Example: UTC +3</li>
<li>Select the time after which the files that are sent/received by guest users will be automatically deleted in days or in hours. The maximum lifespan of files is seven days, to save files beyond seven days contact your system provider. </li>
<li>Select the policies which will be applied on guests for incoming and outgoing files.</li>
<li>Click on the allocation table to see a list of all registered users and determine which of them can transfer files to and from guests. The amount of registered users that can be selected is limited by the guest license allocation. To change it, contact your system provider.</li>
    </ul>
    `,
    accountNotifications: `
      <p>Select in which cases to send the notifications for the registered accounts of the host, by email.</p>`,
    hostNotifications: `
      <p>Select in which cases to send the host admin notifications, by email.</p>`,
    emptyFolders: `
      <p>Select how to handle folders that remain empty after file filtering.</p>
      <p>Example: if all inner files in a folder are expired and deleted after a certain date, it will remain empty.</p>
      <p><b>No auto deletion:</b> empty folders will not be deleted automatically but only manually.</p>
      <p><b>Delete all empty folders:</b> all empty folders will be automatically deleted.</p>
      <p><b>Delete only folders under specified paths:</b> in some cases, it is important to keep empty folders only under a specific path, for example, to maintain the folder hierarchy in an installation folder.</p>
      <p>Insert a path under which all empty folders will be automatically deleted. Folders under all other paths will not be deleted.</p>
    `,
    security: `
    <div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </div>`,
    hostVolume: `
    <div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </div>`,
    SMTPSettings: `
    <div>
      <p>
        The security settings are disables by default to prevent unintentional
        changes.
      </p>

      <p>To make changes, check the 'Enable updating' check box.</p>

      <p>
        <b>Default:</b> use dotEngines' default mail server. you are not able to
        see the servers' details, but you can choose the display name of the
        sender.
      </p>

      <p>
        <b>SMTP/ Microsoft Graph:</b> you can use your own mail server and send
        email via SMTP/ Microsoft Graph protocol. Only the admins of the host
        will be able to see and edit the settings. If you choose to use your own
        mail server definitions it is mandatory that you send a test email to
        make sure everything is set up correctly.
      </p>

      <p>
        <b>Test:</b> send a test email to make sure the mail server details are
        correct. Insert an email address that the test mail will be sent to and
        a message that will be presented in the email's body.
      </p>
    </div>`,
    backupSMTPSettings: `
    <div>
      <p>The security settings are disables by default to prevent unintentional changes.</p>
      <p>To make changes, check the 'Enable updating' check box.</p>
      <p>Set up a backup mail server that will send notifications in case there is a problem with the main mail server.</p>
    </div>`,

    SMSSettings: `
    <div>
      <p>The security settings are disables by default to prevent unintentional changes.</p>
      <p>To make changes, check the 'Enable updating' check box.</p>
      <p><b>Default:</b> use dotEngines' default SMS vendor. you are not able to see the vendors' details, but you can choose the display name of the sender.</p>
      <p><b>Twillo/telnyx:</b> you can use other custom providers instead of dotEngines default option. It is recommended to consult your system provider for more details.</p>
    </div>`,
    passwords: `
    <div>
      <p>Select the password requirements which will apply on the password that the host users will set as their dotEngines credentials.</p>
      <p> The password must include at least 6 numbers. Additional restrictions can be added.</p>
    </div>`,
  },

  systemLogs: {
    settings: {
      Admin: `
      <div>
        <p>Insert the paraments for the system data you wish to present start and end date are mandatory.</p>
        <p>Click on 'advanced' to view the advanced settings and add additional paraments.</p>
      </div>
      `,
      SuperAdmin: `
      <div>
        <p>Insert the paraments for the system data you wish to present start and end date are mandatory.</p>
        <p>Click on 'advanced' to view the advanced settings and add additional paraments.</p>
        <p>You can also show logs per specific host.</p>
        <p> You can choose whether to present logs for inactive hosts as well.</p>
       </div>`,
    },
    advancedSettings: `
    <div>
      <p>Filter the system logs by parameters like free text and session id.<br />
      Customize the log table by choosing which headers to present in the logs table.
      </p>
      <p>Select whether to show local time or UTC time.</p>
    </div>
    `,
  },

  globalSettings: {
    design: `
    <div>
      <p>Set design settings for default dotEngines design: logo, background image and header text.</p>
      <p>The design is presented to all users without hosts (super admins and individuals) and is the default design for all new hosts.</p>
      <p>The recommended background image resolution is 1920 x 1080.</p>
    </div>`,
    SMTPSettings: `
    <div>
      <p>
        The security settings are disables by default to prevent unintentional
        changes.
      </p>
      <p>To make changes, check the 'Enable updating' check box.</p>
      <p>
        View and edit dotEngines default mail server that will be used by all
        hosts in case they set up their own. The settings are only visible to
        super admins.
      </p>
      <p>
        <b>Protocol:</b> Choose whether to use SMTP or Microsoft Graph protocol.
      </p>
      <p>
        <b>Test:</b> Test: send a test email to make sure the mail server
        details are correct. Insert an email address that the test mail will be
        sent to and a message that will be presented in the email's body.
      </p>
    </div>`,
    backupSMTPSettings: `
    <div>
      <p>The security settings are disables by default to prevent unintentional changes.</p>
      <p>To make changes, check the 'Enable updating' check box.</p>
      <p>Set up a backup mail server that will send notifications in case there is a problem with the main mail server.</p>
    </div>
    `,
    SMSSettings: `
    <div>
      <p>The security settings are disables by default to prevent unintentional changes.</p>
      <p>To make changes, check the 'Enable updating' check box.</p>
      <p>View and edit dotEngines default SMS vendor that will be used by all hosts unless they set up their own.
       The settings are only visible to super admins. choose the sender's name that will be displayed.</p>
    </div>
    `,
  },
}
